import getConfig from 'next/config'

import { dev, nodeEnvironment } from '../utils/environment-utils'

const { publicRuntimeConfig } = getConfig()

const {
    BASE_PATH,
    BASE_URL,
    ENV,
    GTM_CONTAINER_ID,
    SENTRY_DSN,
    FIREBASE_API_KEY,
    FIREBASE_APP_ID,
    FIREBASE_MESSAGING_SENDER_ID,
    FIREBASE_PROJECT_ID,
    FIREBASE_AUTH_DOMAIN,
    FIREBASE_STORAGE_BUCKET,
} = publicRuntimeConfig

interface AppConfig {
    basePath: string
    baseUrl: string
    dev: boolean
    environment: 'development' | 'staging' | 'production'
    firebaseApiKey?: string
    firebaseAppId?: string
    firebaseMessagingSenderId?: string
    firebaseProjectId: string
    firebaseAuthDomain: string
    firebaseStorageBucket: string
    gtmContainerId: string
    nodeEnvironment: 'development' | 'production'
    sentryDSN: string
}

const config: AppConfig = {
    basePath: BASE_PATH,
    baseUrl: BASE_URL,
    dev,
    environment: ENV,
    firebaseApiKey: FIREBASE_API_KEY,
    firebaseAppId: FIREBASE_APP_ID,
    firebaseMessagingSenderId: FIREBASE_MESSAGING_SENDER_ID,
    firebaseProjectId: FIREBASE_PROJECT_ID,
    firebaseAuthDomain: FIREBASE_AUTH_DOMAIN,
    firebaseStorageBucket: FIREBASE_STORAGE_BUCKET,
    gtmContainerId: GTM_CONTAINER_ID,
    nodeEnvironment,
    sentryDSN: SENTRY_DSN,
}

export default config
